import React from 'react';
import '../styles/downloadNow.css'; 
const DownloadNow = () => {
  return (
    <div className="download-now-container">
    <div className="download-now-section">
        <div className="download-now-intro">
            <h1>Download Now</h1>
            <h3>Your ultimate AI-powered travel assistant. Plan, explore, and experience unique adventures. </h3>
            <p>Download now and start your journey with ExplorEase. </p>
            <div className="download-app-store-links">
                <img src="/Images/Group 5864.png" alt="Apple Store App Link" />
                <img src="/Images/googlePlayIcon.png" alt="Google Play Store App Link" />
            </div>
        </div>
      <div className="download-now-phone-screens">
        <img src="./Images/downloadNow-phoneScreens.svg" alt="iPhone Screens"/>
      </div>
     
    </div>
    </div>
  );
};

export default DownloadNow;
