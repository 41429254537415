import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "./sanityClient";
import { urlFor } from "./imageBuilder";
import BlockContentRenderer from "./BlockContentRenderer";
import RecentPosts from "./recentPosts";
import "./styles/BlogPost.css";
import SignUp from "./components/signUp";
import RecentBlogPosts from "./components/recentBlogPosts";

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
        _id,
        title,
        body,
        author->{name},
        mainImage,
        publishedAt
      }`,
        { slug }
      )
      .then((data) => setPost(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!post) return null;

  return (
    <div className="blog-post-container">
      <article>
        <h1 className="blog-post-title">{post.title}</h1>
        <img
          src={urlFor(post.mainImage).url()}
          alt={post.title}
          className="blog-post-image"
        />
        <div className="blog-author-date">
          <p className="author">By {post.author.name}</p>
          <p className="date">
            {new Date(post.publishedAt).toLocaleDateString()}
          </p>
        </div>
        <div className="blog-content">
          <BlockContentRenderer blocks={post.body} />
        </div>
      </article>

      <RecentBlogPosts currentPostId={post._id} />
      <SignUp />
    </div>
  );
};

export default BlogPost;
