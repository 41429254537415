import React, { useEffect, useState } from 'react';
import sanityClient from '../sanityClient';
import { urlFor } from '../imageBuilder';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../styles/Home.css';

const RecentBlogPosts = () => {
  const [recentPosts, setRecentPosts] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "post"] | order(publishedAt desc) [0...4] {
        title,
        slug,
        author->{name},
        mainImage,
        publishedAt
      }`)
      .then(data => setRecentPosts(data))
      .catch(console.error);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <button className="slick-next slick-arrow" aria-disabled="false" type="button"></button>,
    prevArrow: <button className="slick-prev slick-arrow" aria-disabled="false" type="button"></button>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="recent-blogs">
      <h2>Recent Blog Posts</h2>
      <Slider {...settings}>
        {recentPosts.map(post => (
          <Link to={`/blogs/${post.slug.current}`} key={post.slug.current} className="blog-card2">
            {post.mainImage && (
              <img src={urlFor(post.mainImage).url()} alt={post.title} className="blog-image" />
            )}
            <div className="blog-content">
              <h3 className="blog-title">{post.title}</h3>
              <div className="author-and-date2">
                <p className="blog-author">By {post.author.name}</p>
                <p className="blog-published-date">{new Date(post.publishedAt).toLocaleDateString()}</p>
              </div>
            </div>
          </Link>
        ))}
      </Slider>
    </div>
  );
};

export default RecentBlogPosts;
