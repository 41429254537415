import React, { useState } from 'react';
import './styles/ContactUs.css';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        comment: ''
    });
    const [status, setStatus] = useState({ message: '', type: '' }); // State to track form submission status and message

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const sendEmail = async (e) => {
        e.preventDefault(); // Prevent the default form submission
        const { firstName, lastName, email, comment } = formData;
        const htmlContent = `
            <h1>New Contact Form Submission</h1>
            <p><strong>First Name:</strong> ${firstName}</p>
            <p><strong>Last Name:</strong> ${lastName}</p>
            <p><strong>Email:</strong> ${email}</p>
            <p><strong>Comment:</strong> ${comment}</p>
        `;

        try {
          const response = await fetch('/api/send-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              to: "info@explorease.com",
              subject: "New Contact Form Submission ExplorEase",
              html: htmlContent,
            }),
          });
    
          if (response.ok) {
            console.log('Email sent successfully');
            setStatus({ message: 'Thank you for your message! We will get back to you soon.', type: 'success' });
            setFormData({ firstName: '', lastName: '', email: '', comment: '' }); // Clear form fields

            // Scroll to top and display success message
            window.scrollTo(0, 0);

            // Hide the success message after 3 seconds
            setTimeout(() => {
                setStatus((prevStatus) => ({ ...prevStatus, fade: true }));
            }, 2000);

            // Remove the message after fade-out
            setTimeout(() => {
                setStatus({ message: '', type: '', fade: false });
            }, 4000);
          } else {
            console.error('Error sending email');
            setStatus({ message: 'There was an error sending your message. Please try again later.', type: 'error' });

            // Scroll to top and display error message
            window.scrollTo(0, 0);

            setTimeout(() => {
                setStatus((prevStatus) => ({ ...prevStatus, fade: true }));
            }, 2000);
            setTimeout(() => {
                setStatus({ message: '', type: '', fade: false });
            }, 4000);
          }
        } catch (error) {
          console.error('Error sending email:', error);
          setStatus({ message: 'There was an error sending your message. Please try again later.', type: 'error' });

          // Scroll to top and display error message
          window.scrollTo(0, 0);
        }
    };

    return (
        <div className="contact-image-container">
            <div className="contact-container">
                <h1>Contact Us</h1>
                <p>Our team is here to help you with any inquiries.</p>
                <div className="contact-form">
                    <div className="send-us-a-message-section">
                        <h2>Send Us a Message</h2>
                        <form onSubmit={sendEmail}>
                            <label htmlFor="firstName">First Name</label>
                            <input 
                                type="text" 
                                id="firstName" 
                                name="firstName" 
                                placeholder="Enter Your First Name" 
                                value={formData.firstName}
                                onChange={handleChange}
                                required 
                            />
                            <label htmlFor="lastName">Last Name</label>
                            <input 
                                type="text" 
                                id="lastName" 
                                name="lastName" 
                                placeholder="Enter Your Last Name" 
                                value={formData.lastName}
                                onChange={handleChange}
                                required 
                            />
                            <label htmlFor="email">Email</label>
                            <input 
                                type="email" 
                                id="email" 
                                name="email" 
                                placeholder="Enter Your Email Address" 
                                value={formData.email}
                                onChange={handleChange}
                                required 
                            />
                            <label htmlFor="comment">Message</label>
                            <textarea 
                                id="comment" 
                                name="comment" 
                                placeholder="Enter Your Message" 
                                rows="8" 
                                value={formData.comment}
                                onChange={handleChange}
                                required
                            ></textarea>
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                    {status.message && (
                        <div className={`form-status ${status.type === 'success' ? 'success' : 'error'} ${status.fade ? 'fade-out' : ''}`}>
                            {status.message}
                        </div>
                    )}
                
                </div>
            </div>
        </div>
    );
}

export default ContactUs;
