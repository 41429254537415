import React, { useEffect, useState } from "react";
import sanityClient from "./sanityClient";
import { urlFor } from "./imageBuilder";
import { Link } from "react-router-dom";
import "./styles/BlogList.css";
import SignUp from "./components/signUp";

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"] | order(publishedAt desc){
        title,
        slug, 
        author->{name},
        mainImage,
        categories[]->{title},
        publishedAt,
        body
      }`
      )
      .then((data) => {
        console.log("Fetched blog posts:", data);
        setBlogs(data);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
      });
  }, []);

  const getCategoryColor = (title) => {
    switch (title) {
      case "Travel Planning":
        return "#5BAD72";
      case "Travel Tips and Hacks":
        return "#BD89FF";
      case "Adventure Travel":
        return "#F1A533";
      case "Family Travel":
        return "#D634DA";
      case "Culinary Journeys":
        return "#DB4545";
      case "Travel Technology":
        return "#4554DB";
      case "Destination Guides":
        return "#31B4EC";
      default:
        return "#CCCCCC";
    }
  };

  return (
    <div className="blog-container">
      {blogs.length > 0 && (
        <>
          <div className="main-blog-post">
            <h1>Featured Blog</h1>
            <Link
              to={`/blogs/${blogs[0].slug.current}`}
              className="main-blog-card"
              style={{ textDecoration: "none" }}
            >
              <div
                className="main-blog-image"
                style={{
                  backgroundImage: `url(${urlFor(blogs[0].mainImage).url()})`,
                }}
              />
              <div className="main-blog-overlay">
                <ul className="main-blog-categories">
                  {blogs[0].categories.map((category, idx) => (
                    <li
                      key={idx}
                      style={{
                        backgroundColor: getCategoryColor(category.title),
                      }}
                    >
                      {category.title}
                    </li>
                  ))}
                </ul>
                <h2 className="main-blog-title">{blogs[0].title}</h2>
                <div className="author-and-published-date">
                  <p className="blog-author">
                    <span className="blog-author-by">By</span>{" "}
                    {blogs[0].author?.name}
                  </p>
                  <p className="blog-published-date">
                    {new Date(blogs[0].publishedAt).toLocaleDateString()}
                  </p>
                </div>
              </div>
            </Link>
            <h2>Recent Blogs</h2>
          </div>

          <div className="blog-list-container">
            {blogs.slice(1).map((blog, index) => (
              <Link
                to={`/blogs/${blog.slug.current}`}
                key={index}
                className="blog-card"
                style={{ textDecoration: "none" }}
              >
                {blog.mainImage && (
                  <img
                    src={urlFor(blog.mainImage).url()}
                    alt={blog.title}
                    className="blog-image"
                  />
                )}
                <ul className="blog-categories">
                  {blog.categories.map((category, idx) => (
                    <li
                      key={idx}
                      style={{
                        backgroundColor: getCategoryColor(category.title),
                      }}
                    >
                      {category.title}
                    </li>
                  ))}
                </ul>
                <h2 className="blog-title">{blog.title}</h2>
                <div className="author-and-published-date">
                  <p className="blog-author">
                    <span className="blog-author-by">By</span>{" "}
                    {blog.author?.name}
                  </p>
                  <p className="blog-published-date">
                    {new Date(blog.publishedAt).toLocaleDateString()}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </>
      )}
      <SignUp />
    </div>
  );
};

export default BlogList;
