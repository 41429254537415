import React from 'react';

const GreencheckMark= () => {
  return (
    <div className="green-check-mark">
    <img src="/Images/green-checkmark.svg" height="27px"alt="Green checkmark"/>
    </div>
  );
};

export default GreencheckMark;