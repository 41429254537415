import React, { useState } from 'react';
import './styles/Pricing.css'; 
import GreenCheckMark from "./components/green-checkmark.js"; // Ensure the path and extension are correct
import BrownCheckMark from "./components/brown-checkmark.js"; // Ensure the path and extension are correct

const Pricing = () => {
  const [isYearly, setIsYearly] = useState(false);

  const togglePricing = () => {
    setIsYearly(!isYearly);
  };

  return (
    <div className="pricing-container">
      <h1 className="pricing-title">Plans & Pricing</h1>
      <p className="pricing-description">Choose the best plan that fits your travel needs and start your journey with ExploreEase.</p>
      <div className="toggle-container">
        <div className={`toggle-label ${!isYearly ? 'active' : ''}`}>Monthly</div>
        <div className={`toggle ${isYearly ? 'yearly' : ''}`} onClick={togglePricing}>
          <div className={`toggle-button ${isYearly ? 'yearly' : 'monthly'}`}></div>
        </div>
        <div className={`toggle-label ${isYearly ? 'active' : ''}`}>Yearly</div>
      </div>
      <div className="plans">
        <div className="plan basic">
          <div className="plan-price basic-price">
            <h2>Basic</h2>
            <p className="price">Free</p>
          </div>
          <ul>
            <div>
              <BrownCheckMark />
              <li> Manage and create bookings for flights, accommodations, rental cars, and activities</li>
            </div>
            <div>
              <BrownCheckMark />
              <li>Provide real-time weather updates and forecasts</li>
            </div>
            <div>
              <BrownCheckMark />
              <li>Instant currency conversion with real-time rates</li>
            </div>
            <div>
              <BrownCheckMark />
              <li>Create and manage personalized travel itineraries</li>
            </div>
            <div>
              <BrownCheckMark />
              <li>Track travel expenses and manage budgets</li>
            </div>
            <div>
              <BrownCheckMark />
              <li>Communicate with locals using text, speech, and image translation.</li>
            </div>
            <div>
              <BrownCheckMark />
              <li>Real-time flight status, updats, and alerts</li>
            </div>
            
          </ul>
        </div>
        <div className="plan premium">
          <div className="plan-price premium-price">
            <h2>Premium</h2>
            <p className="price">{isYearly ? '$39.99/year' : '$3.99/month'}</p>
          </div>
          <ul>
            <div>
              <GreenCheckMark />
              <li>All Basic features included</li>
            </div>
            <div>
              <GreenCheckMark />
              <li>Personalized travel recommendations and AI travel tips</li>
            </div>
           
            <div>
              <GreenCheckMark />
              <li>Local recommendations for attractions, restaurants, and events</li>
            </div>
            <div>
              <GreenCheckMark />
              <li>Receive personalized packing tips and reminders for your trip.</li>
            </div>
            <div>
              <GreenCheckMark />
              <li>Effortlessly record and revisit your travel memories anytime with our digital diary feature.</li>
            </div>
            <div>
              <GreenCheckMark />
              <li>VIP Customer Support</li>
            </div>
            <div>
              <GreenCheckMark />
              <li>Exclusive Discounts and Offers</li>
            </div>
          </ul>
        </div>
      </div>
      <p className="pricing-note">Sign up today and let ExploreEase be your AI-powered travel companion, helping you create unforgettable travel experiences.</p>
    </div>
  );
}

export default Pricing;
