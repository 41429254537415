import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import { urlFor } from "./imageBuilder";
import "./styles/BlogPost.css";

const serializers = {
  types: {
    image: (props) => (
      <img
        src={urlFor(props.node.asset).url()}
        alt={props.node.alt || "Blog image"}
        className="blog-body-image"
      />
    ),
  },
};

const BlockContentRenderer = ({ blocks }) => {
  let imageContainer = [];
  const processedBlocks = [];

  blocks.forEach((block, index) => {
    if (block._type === "image") {
      imageContainer.push(
        <img
          key={index}
          src={urlFor(block.asset).url()}
          alt={block.alt || "Blog image"}
          className="blog-body-image"
        />
      );
    } else {
      if (imageContainer.length > 0) {
        processedBlocks.push(
          <div key={`container-${index}`} className="blog-body-image-container">
            {imageContainer}
          </div>
        );
        imageContainer = [];
      }
      // Process non-image blocks
      processedBlocks.push(
        <BlockContent
          key={index}
          blocks={[block]}
          serializers={serializers}
          projectId="lanabend"
          dataset="production"
        />
      );
    }
  });

  if (imageContainer.length > 0) {
    processedBlocks.push(
      <div key="container-end" className="blog-body-image-container">
        {imageContainer}
      </div>
    );
  }

  return <>{processedBlocks}</>;
};

export default BlockContentRenderer;
