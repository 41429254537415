import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

// Initialize the Sanity client
const sanityClient = createClient({
  projectId: 'lanabend', 
  dataset: 'production', 
  useCdn: true,
  apiVersion: '2024-05-09'  // Use the current date or a date at which your dataset is stable
});

// Set up the image URL builder using the Sanity client
const builder = imageUrlBuilder(sanityClient);

// Function to generate URLs for Sanity images
export const urlFor = (source) => builder.image(source);

// Export the Sanity client as well
export default sanityClient;
