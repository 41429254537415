import React from 'react';

const BrowncheckMark= () => {
  return (
    <div className="brown-check-mark">
    <img src="/Images/brown-checkmark.svg" height="27px"alt="Brown checkmark"/>
    </div>
  );
};

export default BrowncheckMark;