import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './styles/Home.css';
import featuresData from "./components/featuresData";
import DownloadNow from "./components/downloadNow";
import SignUp from "./components/signUp";
import RecentBlogPosts from "./components/recentBlogPosts"

const HomePage = () => {
  const FeatureCard = ({ title, description, imgSrc, imgAlt, backgroundColor }) => (
    <div className="why-choose-us-cards">
      <div style={{ backgroundColor }}> 
        <img src={imgSrc} alt={imgAlt} />
      </div>
      <div className="why-choose-us-cards-info">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
  return (
    <div className="home-page">
      <header className="home-header">
        <div className="home-header-container">
          <h1>Explore The World With Ease</h1>
          <p>Your <span>AI</span> Powered Travel Assistant</p>
          <p>This is your ultimate travel companion to help you plan trips, explore destinations, and more.</p>
          <div className="download-app-store-links">
            <img src="/Images/Group 5864.png" alt="Apple Store App Link" />
            <img src="/Images/googlePlayIcon.png" alt="Google Play Store App Link" />
          </div>
      
        </div>
        <div className="home-content">
          <div className="phone-wrapper">
            <img src="/Images/phonescreens.png" alt="ExplorEase App Screens" height="430px" width="380"/>
          </div>
        </div>
      </header>

      <div className="why-choose-us">
        <h2>Why Choose Us</h2>
        <p className="why-choose-us-p">Enjoy different experiences in every place you visit and discover new and affordable adventures of course.</p>
        <div className="why-choose-us-card-container">
          {featuresData.map(feature => (
            <FeatureCard key={feature.id} {...feature} />
          ))}
        </div>
      </div>

      <div className="multiple-phone-screen-display">
        <img src="./Images/phonescreens.svg" alt="Phone Screens Display"/>
      </div>

      {/* <div className="adventure-container">
          <div className="adventure-image">
              <img src="/Images/familyHiking.svg" width="600px" alt="family-hiking"/>
          </div>
          <div className="advetnture-info">
              <h2>Ready for Adventure?<br></br> Your Journey Begins Here</h2>
              <p>Set off on journeys filled with discovery. Every trail offers a chance to create lasting memories and uncover the wonders of the world around you.</p>
          </div>

      </div> */}

      <RecentBlogPosts/>
      <SignUp/>
      <DownloadNow/>
    </div>
  );
};

export default HomePage;
