const featuresData = [
    {
      id: 1,
      title: "Comprehensive Booking Hub",
      description: "Effortlessly manage & create bookings for flights, accommodations, rental cars, & activities—all from one intuitive platform.",
      imgSrc: "./Images/airplane.svg",
      imgAlt: "Booking Hub Icon",
      backgroundColor: "#42C4DE"
    },
    {
      id: 2,
      title: "Dynamic Travel Insights",
      description: "Receive personalized recommendations for activities and local event alerts based on your preferences and past travel behavior.",
      imgSrc: "./Images/lightbulb.svg",
      imgAlt: "Travel Insights Icon",
      backgroundColor: "#5BAD72"
    },
    {
      id: 3,
      title: "Smart Packing Assistant",
      description: "Get packing suggestions based on your destination and weather, including reminders to ensure you don't forget a thing.",
      imgSrc: "./Images/Checklist.svg",
      imgAlt: "Packing Assistant Icon",
      backgroundColor: "#F9A86E"
    },


    {
        id: 4,
        title: "Reflection Journal",
        description: "Keep a digital diary of your travels with the reflection journal feature, allowing you to record and revisit your adventures and memories anytime.",
        imgSrc: "./Images/journal-icon.svg",
        imgAlt: "Custom It Insights Icon",
        backgroundColor: "#DC9BFA" 
    },


    {
        id: 5,
        title: "Currency Converter",
        description: "Easily convert currencies on the go with real-time exchange rates, ensuring you always know how much you're spending.",
        imgSrc: "./Images/currency-icon.svg",
        imgAlt: "Currency Converter Icon",
        backgroundColor:  "#83D8AD"  
    },

    {
        id: 6,
        title: "Integrated Budget Tracker",
        description: "Monitor and manage your travel expenses in real-time, helping you stay on budget and make informed financial decisions.",
        imgSrc: "./Images/budget-tracker-icon.svg",
        imgAlt: "Budget Tracke Icon",
        backgroundColor: "#4542DE"
    },

    {
        id: 7,
        title: "Custom Itinerary Builder",
        description: "Create personalized travel itineraries with ease, including activities, accommodations, and transportation, tailored to your preferences.",
        imgSrc: "./Images/builder-icon.svg",
        imgAlt: "Travel Insights Icon",
        backgroundColor: "#E641C2" 
    },

    {
        id: 8,
        title: "Real-Time Language Translator",
        description: "Our real-time translator, allowing you to communicate effortlessly with locals through text, speech, and image translation.",
        imgSrc: "./Images/translator-icon.svg",
        imgAlt: "Builder Tools Icon",
        backgroundColor: "#DE4255" 
    },
  ];
  export default featuresData;
  