import React from 'react';
import './styles/AboutUs.css';
import DownloadNow from '../src/components/downloadNow';

const AboutUs = () => {
  return (
    <div className="about-container">
      <section className="about-header">
        <h1>About Us</h1>
      </section>
      <div className="benefits-section">
        <h2>Discover Our Top <span className="span-benefits">Benefits</span></h2>
        <p className="benefits-subtitle">At ExplorEase, we strive to enhance every aspect of your travel experience. Our top benefits are designed to make your journey smoother, more enjoyable, and entirely personalized to your needs.</p>
        <div className="benefits-cards">
          <div className="benefit-card">
            <img src="/Images/about-travel.svg" alt="Exclusive Trip Icon" />
            <h3>Exclusive Trips</h3>
            <p>Experience unique and carefully curated trips designed to offer you memorable and exclusive travel experiences.</p>
          </div>
          <div className="benefit-card">
            <img src="/Images/about-plane-ticket.svg" alt="Diverse Choices Icon" />
            <h3>Travel Management</h3>
            <p>Keep track of travel expenses, manage budgets, and receive real-time weather updates and forecasts to stay prepared throughout your journey.</p>
          </div>
          <div className="benefit-card">
            <img src="/Images/about-guide.svg" alt="Expert Guidance Icon" />
            <h3>Personalized Assistance</h3>
            <p>Get personalized travel recommendations, AI travel tips, and local recommendations for attractions, restaurants, and events to make the most out of your travels.</p>
          </div>
        </div>
      </div>
      <div className="cherished-memories-section">
        <div className="cherished-memories">
          <h2>Every Moment Becomes a Cherished Memory</h2>
          <p>In the heart of ExplorEase, where dreams take flight, we believe that travel is not just a journey from one place to another—it's a meaningful and enriching experience that transforms and inspires.</p>
        </div>
        <div className="cherished-memories-img">
          <img src="/Images/about-world-map.svg" alt="World Map"/>
        </div>
      </div>
      <div className="download-now-container" style={{ background: 'linear-gradient(to top, #8ECBDB 14%, #FFFFFF 100%)' }}>
    <div className="download-now-section">
        <div className="download-now-intro">
            <h1>Download Now</h1>
            <h3>Your ultimate AI-powered travel assistant. Plan, explore, and experience unique adventures. </h3>
            <p>Download now and start your journey with ExplorEase. </p>
            <div className="download-app-store-links">
                <img src="/Images/Group 5864.png" alt="Apple Store App Link" />
                <img src="/Images/googlePlayIcon.png" alt="Google Play Store App Link" />
            </div>
        </div>
      <div className="download-now-phone-screens">
        <img src="./Images/downloadNow-phoneScreens.svg" alt="iPhone Screens"/>
      </div>
     
    </div>
    </div>
    </div>
  );
}

export default AboutUs;
