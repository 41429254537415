import React from 'react';
import "../styles/signup.css";

const SignUp = () => {
  return (
    <div className="newsletter-parent-container">
      <div className="newsletter-signup">
        <div>
          <img src="/Images/mail-newsletter.svg" alt="Mail Icon" className="signup-icon" />
        </div>
        <div id="mc_embed_shell">
          <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
          <style type="text/css">
            {`
              #mc_embed_signup {
                clear: left;
    
                width: 100%;
                margin: 0 auto;
                padding: 20px;
                
              }
              #mc_embed_signup h2 {
                color: #333;
                font-size: 24px;
                margin-bottom: 10px;
                
              }
              #mc_embed_signup .signup-description {
                color: black;
                font-size: 18px;
                margin-bottom: 20px;
              }
              #mc_embed_signup .mc-field-group label {
                display: none;
                width:auto;
                
              }
              #mc_embed_signup input[type="email"] {
                width: 400px;
                padding: 10px;
                border-radius: 8px;
                border: 1px solid #ddd;
                margin-right: 10px;
              }
              #mc_embed_signup input[type="submit"] {
                
                border: none;
                border-radius: 8px;
                background-color: #42C2DF;
                color: white;
                cursor: pointer;
                transition: background-color 0.3s;
                font-weight: 800;
              }
              #mc_embed_signup input[type="submit"]:hover {
                background-color: #5BAD72;
              }
            `}
          </style>
          <div id="mc_embed_signup">
            <form
                action="https://explorease.us17.list-manage.com/subscribe/post?u=8e1afde4f57e0a5150b5b3377&amp;id=68baa9800c&amp;f_id=0098c2e1f0"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_self"
                novalidate=""
            >
              <div id="mc_embed_signup_scroll">
                <h2>Sign Up for Our Newsletter</h2>
                <p className="signup-description">
                  Stay updated with the latest travel tips, destination guides, and exclusive offers from ExplorEase.
                </p>
                <div className="indicates-required">
                  <span className="asterisk">*</span> indicates required
                </div>
                <div className="mailchimp-form">
                <div className="mc-field-group">
                  <label htmlFor="mce-EMAIL">
                    Email Address <span className="asterisk">*</span>
                  </label>
                  <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="Your Email" required />
                </div>
                <div id="mce-responses" className="clear">
                  <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                  <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                </div>
                <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                  <input
                    type="text"
                    name="b_8e1afde4f57e0a5150b5b3377_68baa9800c"
                    tabIndex="-1"
                    value=""
                  />
                </div>
                <div className="clear">
                  <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Subscribe" />
                </div>
                </div>
              </div>
            </form>
          </div>
          <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
          <script type="text/javascript">
            {`
              (function($) {
                window.fnames = new Array();
                window.ftypes = new Array();
                fnames[0] = 'EMAIL';
                ftypes[0] = 'email';
                fnames[1] = 'FNAME';
                ftypes[1] = 'text';
                fnames[2] = 'LNAME';
                ftypes[2] = 'text';
                fnames[3] = 'ADDRESS';
                ftypes[3] = 'address';
                fnames[4] = 'PHONE';
                ftypes[4] = 'phone';
                fnames[5] = 'BIRTHDAY';
                ftypes[5] = 'birthday';
              }(jQuery));
              var $mcj = jQuery.noConflict(true);
            `}
          </script>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
